import {
    Box,
    Button,
    Input,
    Heading,
    Flex,
    Text,
    Link,
    Image,
    FormControl,
    FormLabel,
    useToast,
  } from '@chakra-ui/react';
  import { FcGoogle } from 'react-icons/fc';
  import {
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
  } from 'firebase/auth';
  import { auth } from '../Firebase';
  import { useState } from 'react';
  import { useNavigate, Link as RouterLink } from 'react-router-dom';
  import banner from '../assets/join-banner.webp';
  import logo from '../assets/logo.png';
  
  // Import motion and AnimatePresence from framer-motion
  import { motion, AnimatePresence } from 'framer-motion';
  
  // Create a motion-enabled Box component
  const MotionBox = motion(Box);
  
  function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const toast = useToast();
  
    const handleLogin = async () => {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/dashboard');
      } catch (error) {
        toast({
          title: 'Login Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
  
    const handleGoogleLogin = async () => {
      try {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        navigate('/dashboard');
      } catch (error) {
        toast({
          title: 'Google Login Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
  
    return (
      <Flex h="100vh" direction={{ base: 'column', md: 'row' }}>
        {/* Left Side */}
        <Flex
          flex="1"
          alignItems="center"
          justifyContent="center"
          p={8}
          position="relative" // Added position relative
        >
          {/* Adjusted Link Component */}
          <Link
            as={RouterLink}
            to="/"
            position="absolute"
            top={10}
            left={0}
            right={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={4}
            textDecoration="none"
            _hover={{ textDecoration: 'none' }}
          >
            <Image src={logo} alt="Logo" h="40px" mr={2} />
            <Text fontSize="3xl" fontWeight="bold">
              hoo.be
            </Text>
          </Link>
  
          {/* Wrap the entire left side content with AnimatePresence and MotionBox */}
          <AnimatePresence>
            <MotionBox
              key="login"
              maxW="600px"
              w="full"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              <Heading mb={6} textAlign="center">
                Welcome Back
              </Heading>
  
              <FormControl mb={4}>
                <FormLabel>Email</FormLabel>
                <Input
                  placeholder="Email"
                  value={email}
                  size="lg"
                  onChange={(e) => setEmail(e.target.value)}
                  borderRadius="xl"
                />
              </FormControl>
  
              <FormControl mb={4}>
                <Flex justifyContent="space-between" alignItems="center">
                  <FormLabel>Password</FormLabel>
                  <Link color="blue.500" onClick={() => navigate('/reset-password')}>
                    Forgot password?
                  </Link>
                </Flex>
                <Input
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size="lg"
                  borderRadius="xl"
                />
              </FormControl>
  
              <Button
                onClick={handleLogin}
                colorScheme="blue"
                w="full"
                mb={4}
                size="lg"
                borderRadius="xl"
              >
                Login
              </Button>
              <Button
                onClick={handleGoogleLogin}
                w="full"
                mb={4}
                leftIcon={<FcGoogle />}
                size="lg"
                borderRadius="xl"
              >
                Continue with Google
              </Button>
  
              <Text mt={4} textAlign="center">
                Don’t have an account?{' '}
                <Link color="blue.500" onClick={() => navigate('/signup')}>
                  Sign Up
                </Link>
              </Text>
            </MotionBox>
          </AnimatePresence>
        </Flex>
  
        {/* Right Side */}
        <Box flex="1" display={{ base: 'none', md: 'block' }}>
          <Image
            src={banner}
            alt="Login Illustration"
            objectFit="cover"
            w="100%"
            h="100vh"
            p={10}
            borderRadius="80px"
          />
        </Box>
      </Flex>
    );
  }
  
  export default Login;
  