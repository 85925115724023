import {
  Box,
  Button,
  Input,
  Heading,
  Flex,
  Text,
  Link,
  Image,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  useToast,
  Spinner,
  InputLeftElement,
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { FaYoutube, FaInstagram, FaTiktok } from 'react-icons/fa';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { auth, functions } from '../Firebase';
import { httpsCallable } from 'firebase/functions';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import debounce from 'lodash/debounce';
import banner from '../assets/join-banner.webp';
import logo from '../assets/logo.png';

import { motion, AnimatePresence } from 'framer-motion';

const MotionBox = motion(Box);

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hoobeHandle, setHoobeHandle] = useState('');
  const [step, setStep] = useState(1);
  const [isHandleAvailable, setIsHandleAvailable] = useState(null);
  const [isChecking, setIsChecking] = useState(false);

  // New state variables for social media handles
  const [youtubeHandle, setYoutubeHandle] = useState('');
  const [instagramHandle, setInstagramHandle] = useState('');
  const [tiktokHandle, setTiktokHandle] = useState('');

  const [isSigningUp, setIsSigningUp] = useState(false); // New state for signup process

  const navigate = useNavigate();
  const toast = useToast();

  const checkUsernameAvailability = useCallback((handle) => {
    return httpsCallable(functions, 'checkUsernameAvailability')({ handle });
  }, []);

  const debouncedCheckAvailability = useMemo(
    () =>
      debounce((handle) => {
        if (handle.trim() === '') {
          setIsHandleAvailable(null);
          setIsChecking(false);
          return;
        }

        setIsChecking(true);
        checkUsernameAvailability(handle)
          .then((result) => {
            setIsHandleAvailable(result.data.available);
          })
          .catch((error) => {
            console.error('Error checking handle availability:', error);
            setIsHandleAvailable(null);
          })
          .finally(() => {
            setIsChecking(false);
          });
      }, 500),
    [checkUsernameAvailability]
  );

  useEffect(() => {
    debouncedCheckAvailability(hoobeHandle);

    return () => debouncedCheckAvailability.cancel();
  }, [hoobeHandle, debouncedCheckAvailability]);

  const handleSignup = async () => {
    setIsSigningUp(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const createUserInFirestore = httpsCallable(
        functions,
        'createUserInFirestore'
      );
      await createUserInFirestore({
        handle: hoobeHandle,
        email: user.email,
        // You can include social media handles here if needed
        // youtube: youtubeHandle,
        // instagram: instagramHandle,
        // tiktok: tiktokHandle,
      });

      navigate('/dashboard');
    } catch (error) {
      console.error('Signup Error:', error.message);
      toast({
        title: 'Signup Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSigningUp(false);
    }
  };

  const handleGoogleSignup = async () => {
    setIsSigningUp(true);
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      const createUserInFirestore = httpsCallable(
        functions,
        'createUserInFirestore'
      );
      await createUserInFirestore({
        handle: hoobeHandle,
        email: user.email,
        // You can include social media handles here if needed
        // youtube: youtubeHandle,
        // instagram: instagramHandle,
        // tiktok: tiktokHandle,
      });

      navigate('/dashboard');
    } catch (error) {
      console.error('Google Signup Error:', error.message);
      toast({
        title: 'Google Signup Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSigningUp(false);
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const isNextDisabled = isHandleAvailable !== true;

  return (
    <Flex h="100vh" direction={{ base: 'column', md: 'row' }}>
      <Flex
        flex="1"
        alignItems="center"
        justifyContent="center"
        p={8}
        position="relative"
      >
        <Link
          as={RouterLink}
          to="/"
          position="absolute"
          top={10}
          left={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={4}
          textDecoration="none"
          _hover={{ textDecoration: 'none' }}
        >
          <Image src={logo} alt="Logo" h="40px" mr={2} />
          <Text fontSize="3xl" fontWeight="bold">
            hoo.be
          </Text>
        </Link>

        <AnimatePresence mode="wait">
          <MotionBox
            key={step}
            maxW="600px"
            w="full"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <Heading mb={6} textAlign="center">
              {step === 1 && 'Claim your hoo.be handle'}
              {step === 2 && 'Add Your Links'}
              {step === 3 && `Create an account to claim hoo.be/${hoobeHandle}`}
            </Heading>

            {step === 1 && (
              <Box>
                <FormControl mb={4} isInvalid={isHandleAvailable === false}>
                  <InputGroup>
                    <InputLeftElement>
                      <Text color="gray.500" pl={20} fontSize="20px" mt={2}>
                        hoo.be/
                      </Text>
                    </InputLeftElement>
                    <Input
                      placeholder="Enter your hoobe handle"
                      value={hoobeHandle}
                      onChange={(e) => setHoobeHandle(e.target.value)}
                      size="lg"
                      borderRadius="xl"
                      pl={110}
                    />
                    {isChecking && (
                      <InputRightElement>
                        <Spinner mt={2} mr={2} size="md" />
                      </InputRightElement>
                    )}
                  </InputGroup>
                  <Box h="25px" mt={4}>
                    {isHandleAvailable === false && (
                      <Text color="red.500" fontWeight="bold" textAlign="center">
                        This handle is already taken.
                      </Text>
                    )}
                    {isHandleAvailable === true && (
                      <Text
                        color="green.500"
                        fontWeight="bold"
                        textAlign="center"
                      >
                        This handle is available!
                      </Text>
                    )}
                  </Box>
                </FormControl>

                <Button
                  onClick={handleNextStep}
                  colorScheme="blue"
                  w="full"
                  mt={4}
                  isDisabled={isNextDisabled}
                  size="lg"
                  borderRadius="xl"
                >
                  Claim Handle
                </Button>
              </Box>
            )}

            {step === 2 && (
              <Box
                maxW="350px"
                alignContent="center"
                alignItems="center"
                mx="auto"
              >
                <FormControl mb={4}>
                  <InputGroup>
                    <InputLeftElement mt={1} ml={1} pointerEvents="none">
                      <FaYoutube size="30px" color="#FF0000" />
                    </InputLeftElement>
                    <Input
                      placeholder="Enter your YouTube handle"
                      value={youtubeHandle}
                      onChange={(e) => setYoutubeHandle(e.target.value)}
                      size="lg"
                      borderRadius="xl"
                    />
                  </InputGroup>
                </FormControl>

                <FormControl mb={4}>
                  <InputGroup>
                    <InputLeftElement mt={1} ml={1} pointerEvents="none">
                      <FaInstagram size="30px" color="#C13584" />
                    </InputLeftElement>
                    <Input
                      placeholder="Enter your Instagram handle"
                      value={instagramHandle}
                      onChange={(e) => setInstagramHandle(e.target.value)}
                      size="lg"
                      borderRadius="xl"
                    />
                  </InputGroup>
                </FormControl>

                <FormControl mb={4}>
                  <InputGroup>
                    <InputLeftElement mt={1} ml={1} pointerEvents="none">
                      <FaTiktok size="30px" color="#000000" />
                    </InputLeftElement>
                    <Input
                      placeholder="Enter your TikTok handle"
                      value={tiktokHandle}
                      onChange={(e) => setTiktokHandle(e.target.value)}
                      size="lg"
                      borderRadius="xl"
                    />
                  </InputGroup>
                </FormControl>

                <Button
                  onClick={handleNextStep}
                  colorScheme="blue"
                  w="full"
                  mt={4}
                  size="lg"
                  borderRadius="xl"
                >
                  Next
                </Button>
              </Box>
            )}

            {step === 3 && (
              <Box>
                <FormControl mb={4}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    placeholder="Email"
                    value={email}
                    size="lg"
                    onChange={(e) => setEmail(e.target.value)}
                    borderRadius="xl"
                  />
                </FormControl>

                <FormControl mb={4}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="lg"
                    borderRadius="xl"
                  />
                </FormControl>

                <Button
                  onClick={handleSignup}
                  colorScheme="blue"
                  w="full"
                  mb={4}
                  size="lg"
                  borderRadius="xl"
                  isLoading={isSigningUp}
                  isDisabled={isSigningUp}
                >
                  Sign Up
                </Button>
                <Button
                  onClick={handleGoogleSignup}
                  w="full"
                  mb={4}
                  leftIcon={<FcGoogle />}
                  size="lg"
                  borderRadius="xl"
                  isLoading={isSigningUp}
                  isDisabled={isSigningUp}
                >
                  Continue with Google
                </Button>
              </Box>
            )}

            <Text mt={4} textAlign="center">
              Already have an account?{' '}
              <Link color="blue.500" onClick={() => navigate('/login')}>
                Log In
              </Link>
            </Text>
          </MotionBox>
        </AnimatePresence>
      </Flex>

      {/* Right Side */}
      <Box flex="1" display={{ base: 'none', md: 'block' }}>
        <Image
          src={banner}
          alt="Signup Illustration"
          objectFit="cover"
          w="100%"
          h="100vh"
          p={10}
          borderRadius="80px"
        />
      </Box>
    </Flex>
  );
}

export default Signup;
