import { Box, Button, Heading, Text, Flex } from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import { auth, db } from '../Firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Dashboard() {
  const [userData, setUserData] = useState(null); 
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setUserData(docSnapshot.data());
        } else {
          console.log('User data not found in Firestore');
        }
      });

      return () => unsubscribe();
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Flex minH="100vh" align="center" justify="center" bg="gray.50">
      <Box bg="white" p={8} rounded="md" boxShadow="lg" w="500px">
        <Heading mb={6} textAlign="center">Dashboard</Heading>
        {userData ? (
          <Box mb={6}>
            <Text>Email: {userData.email}</Text>
            <Text>UID: {userData.uid}</Text>
            <Text>Handle: {userData.handle}</Text>
            <Text>Created At: {new Date(userData.createdAt.seconds * 1000).toLocaleDateString()}</Text>
          </Box>
        ) : (
          <Text>Loading Firestore user data...</Text>
        )}
        <Button onClick={handleLogout} colorScheme="red" w="full">
          Logout
        </Button>
      </Box>
    </Flex>
  );
}

export default Dashboard;
