import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import { AuthProvider } from './components/utils/AuthContext';
import ProtectedRoute from './components/utils/ProtectedRoute';
import DirectRoute from './components/utils/DirectRoute';

const theme = extendTheme({
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  colors: {
    blue: {
      300: "#b0e6eb",
      400: "#66cbd4",
      500: "#5cb5bd",
      600: "#44939a",
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/login"
            element={
              <DirectRoute>
                <Login />
              </DirectRoute>
            }
          />
        </Routes>
      </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
